import React from "react";
import "../WhiteBtn/WhiteBtn.css";

function WhiteBtn({ BtnName, btnType, onClickBtn }) {
  return (
    <>
      <div className="white-btn">
        <button type={btnType} onClick={onClickBtn} className="btn-white">
          <span className="transition"></span>
          <span className="btn-text-white"> {BtnName}</span>
        </button>
      </div>
    </>
  );
}

export default WhiteBtn;
