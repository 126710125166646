import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ServiceCardBanner from "./Components/SeviceCardBanner/ServiceCardBanner";
import WebDevelopment from "./Components/WebDevelopment/WebDevelopment";
import Digital from "./Components/digital/Digital";
import Film from "./Components/film/Film";
import Video from "./Components/video/Video";
import Cgi from "./Components/Cgi/Cgi";
import Graphic from "./Components/Graphic/Graphic";
import Contact from "./Components/Contact/Contact";
import Thankyou from "./Components/ThankYou/Thankyou";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
      <Footer/>

    </>
  );
}

export default App;
