import React from "react";
import ServiceCardBanner from "../SeviceCardBanner/ServiceCardBanner";
import web from "../../assets/web.gif";

function WebDevelopment() {
  const developList = [
    "Custom Website Design & Development",
    "eCommerce Solutions",
    "Landing Pages",
    "Content Management Systems (CMS)",
  ];
  return (
    <div>
      <ServiceCardBanner
        title="Web Development"
        description="Your website is the digital address for your brand. We build responsive, SEO-friendly, and user-centric websites that ensure seamless navigation and excellent user experience. Our web development services include"
        servicesList={developList}
        desc='Whether you need a personal portfolio or a robust eCommerce site, we tailor solutions to your business goals.'
        gif={web}
        navilink={"https://drive.google.com/drive/folders/1S4heqan6rSoHU5_YBT7V-tu0w6RNb9x1?usp=sharing"}
      />
    </div>
  );
}

export default WebDevelopment;
