import React from 'react';
import ServiceCardBanner from '../SeviceCardBanner/ServiceCardBanner';
import cgi from "../../assets/cgi.gif";


function Cgi() {
  const cgiList = [
    "3D Animation",
    "Visual Effects (VFX)",
    "Product Simulations",
    "Architectural Visualizations",
  ];
  return (
    <div>
      <ServiceCardBanner
        title="CGI Videos"
        description="Enhance your visual storytelling with cutting-edge CGI videos. Whether you're looking to showcase complex products or create fantastical worlds, our CGI team brings your imagination to reality. Our services include"
        servicesList={cgiList}
        desc="Engage viewers with immersive, high-quality CGI visuals."
        gif={cgi}
        navilink="https://drive.google.com/drive/folders/16sNl10CoJkEhY55k9nja3kwgD7hPHQeS?usp=drive_link"
      />
    </div>
  );
}

export default Cgi;
