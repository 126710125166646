import React from 'react';
import ServiceCardBanner from '../SeviceCardBanner/ServiceCardBanner';
import video from "../../assets/video.gif";

function Video() {
  const videoList = [
    "Brand Commercials",
    "Social Media Ads",
    "TV Advertisements",
    "Product Launch Videos",
  ];
  return (
    <div>
      <ServiceCardBanner
        title="Video Advertisement"
        description="Grab your audience’s attention with compelling video ads. From concept to execution, our team crafts videos that resonate with your target audience. Our  services include"
        servicesList={videoList}
        desc="We specialize in creating high-impact advertisements that highlight your brand’s message and drive results."
        gif={video}
        navilink="https://drive.google.com/drive/folders/1GTQgMtcBUPA0aCnUyAp93RD21pKQRV9N?usp=drive_link"
      />
    </div>
  );
}

export default Video;
