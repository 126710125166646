import React from "react";
import ServiceCardBanner from "../SeviceCardBanner/ServiceCardBanner";
import gif1 from "../../assets/Animation1.gif";

function Graphic() {
  const graphicDesignServices = [
    "Logo Design",
    "Branding & Visual Identity",
    "Social Media Graphics",
    "Brochures, Flyers, & Print Design",
  ];
  return (
    <div>
      <ServiceCardBanner
        title="Graphic Designing"
        description="First impressions matter. Our expert graphic designers create visuals that not only captivate but also convert. From logos to brand identities and everything in between, we help businesses stand out."
        servicesList={graphicDesignServices}
        desc="Let us transform your ideas into visually stunning graphics that speak loudly for your brand."
        gif={gif1}
        navilink="https://drive.google.com/drive/folders/1c_zvx62I_a821xk6pgcbuk6yAbaUX3J1?usp=sharing"
      />
    </div>
  );
}

export default Graphic;
