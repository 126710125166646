import React from 'react';
import '../ThankYou/Thankyou.css'
function Thankyou() {
  return (
    <>
    <div className="thank-you-container">
    <h1 className="thank-you-text">Thank You!</h1>
    <div className="sparkle"></div>
    <div className="p-text">For Connecting With Us</div>
  </div>
  </>
  );
}

export default Thankyou;
