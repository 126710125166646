import React from 'react';
import ServiceCardBanner from '../SeviceCardBanner/ServiceCardBanner';
import film from "../../assets/film.gif";


function Film() {
  const filList = [
    "Corporate Videos",
    "Promotional Films",
    "Event Coverage",
    "Explainer Videos",
  ];
  return (
    <div>
      <ServiceCardBanner
        title="Film Production"
        description="Capture your audience's attention with professionally crafted videos. From brand commercials to promotional videos, we bring your story to life. Our film production services include"
        servicesList={filList}
        desc="Tell your story in the most compelling way with high-quality video content."
        gif={film}
        navilink="https://drive.google.com/drive/folders/1dd3hR0xHgtEnAgMuk4UuugPLP_Uykw-5?usp=drive_link"
      />
    </div>
  );
}

export default Film;
