import React, { useEffect } from "react";
import solutions from "../../assets/solution.svg";
import team from "../../assets/team.svg";
import service from "../../assets/service.svg";
import result from "../../assets/result.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import potential from '../../assets/potential.png'

gsap.registerPlugin(ScrollTrigger);

function Why() {

  useEffect(() => {
    const mm = gsap.matchMedia(); 

    mm.add("(min-width: 769px)", () => {

      // First image animation from the left with rotation
      gsap.fromTo(
        ".service-item:nth-child(1) .right",
        { x: -300, y: -280, scale: 1.5, opacity: 0, rotate: -180 },
        {
          x: 0,
          y: 0,
          scale: 1,
          opacity: 1,
          rotate: 0,
          duration: 2,
          scrollTrigger: {
            trigger: ".service-item:nth-child(1) .right",
            start: "top center",
            end: "bottom 10%",
            scrub: true,
          },
        }
      );

      // Second image animation from the right with rotation
      gsap.fromTo(
        ".service-item:nth-child(2) .left",
        { x: 300, y: -280, scale: 1.5, opacity: 0, rotate: -180 },
        {
          x: 0,
          y: 0,
          scale: 1,
          opacity: 1,
          rotate: 0,
          duration: 2,
          scrollTrigger: {
            trigger: ".service-item:nth-child(2) .left",
            start: "top center",
            end: "bottom 10%",
            scrub: true,
          },
        }
      );

      // Third image animation from the top
      gsap.fromTo(
        ".team-itemm .bottom",
        { y: -500, opacity: 0, rotate: -180, scale: 1.5 },
        {
          y: 0,
          scale: 1,
          opacity: 1,
          rotate: 0,
          duration: 2,
          scrollTrigger: {
            trigger: ".team-itemm .bottom",
            start: "top center",
            end: "bottom 10%",
            scrub: true,
          },
        }
      );

      // Fourth image animation from the top
      gsap.fromTo(
        ".team-item .bottom",
        { y: -500, opacity: 0, rotate: -180, scale: 1.5 },
        {
          y: 0,
          scale: 1,
          opacity: 1,
          rotate: 0,
          duration: 2,
          scrollTrigger: {
            trigger: ".team-item .bottom",
            start: "top center",
            end: "bottom 10%",
            scrub: true,
          },
        }
      );
    });

    gsap.fromTo(
      ".img-size-30", 
      { scale: 0.5 }, // Start with scale 0.5 (zoomed out)
      { 
        scale: 1.5, // Zoom in to 1.5 scale
        scrollTrigger: {
          trigger: ".zoom-element", 
          start: "top 80%", // When top of the element reaches 80% of the viewport height
          end: "bottom 20%", // When bottom of the element reaches 20% of the viewport height
          scrub: true // Smooth animation as the user scrolls
        }
      }
    );

    return () => {
      mm.revert(); // Clean up media queries and GSAP effects when the component unmounts
    };
  }, []);
  

  return (
    <>
      <section className="four-section bg-white">
        <div className="top-four-container">
          <div className="why-flourish">
            Why Choose{" "}
            <span className="grow-container">
              Flourish?
              <img
                src={potential}
                alt="underline"
                className="potential-underline"
              />
            </span>
          </div>
          <div className="grid-container">
            {/* Service Items */}
            <div className="service-item">
              <div className="img-div-right">
                <div className="black-cirlce">
                  <img src={solutions} alt="" className="img-size-30 right imgs-ani-why" />
                </div>
              </div>
              <div className="title-why-right">Tailored Solutions</div>
              <div className="why-desc">
                Every business is unique, and so are our strategies. We develop
                customized strategy and marketing solutions as per your unique
                needs and goals.
              </div>
            </div>
            <div className="service-item">
              <div className="img-div-left">
                <div className="black-cirlce">
                  <img src={team} alt="" className="img-size-30 left imgs-ani-why" />
                </div>
              </div>
              <div className="title-why-left">Experienced Team</div>
              <div className="why-desc">
                Our team of skilled designers, developers, marketers, and
                filmmakers brings years of industry experience to deliver
                results.
              </div>
            </div>

            {/* Team Items */}
            <div className="team-itemm">
              <div className="img-div-right">
                <div className="black-cirlce">
                  <img src={service} alt="" className="img-size-30 bottom imgs-ani-why" />
                </div>
              </div>
              <div className="title-why-right">End-to-End Services</div>
              <div className="why-desc">
                From creative design to digital marketing and video production,
                we offer a complete range of services to meet your business
                needs.
              </div>
            </div>
            <div className="team-item">
              <div className="img-div-left">
                <div className="black-cirlce">
                  <img src={result} alt="" className="img-size-30 bottom imgs-ani-why" />
                </div>
              </div>
              <div className="title-why-left">Proven Results</div>
              <div className="why-desc">
                We have helped numerous clients grow their brand visibility,
                improve engagement, and achieve long-term success.
              </div>
              <div className="p-line">
              <div className="hr-line"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Why;
