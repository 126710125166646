import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import one from "../../assets/one.svg";
import two from "../../assets/two.svg";
import three from "../../assets/three.svg";
import four from "../../assets/four.svg";
import five from "../../assets/five.svg";
import six from "../../assets/six.svg";
import TopEmojiImg from "../../assets/top.png";
import logo from "../../assets/logo.svg";
import Button from "../../Components/Button/Button";
import WhiteBtn from "../../Components/WhiteBtn/WhiteBtn";
import ServiceCardBanner from "../../Components/SeviceCardBanner/ServiceCardBanner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Home/why.css";
import Graphic from "../../Components/Graphic/Graphic";
import WebDevelopment from "../../Components/WebDevelopment/WebDevelopment";
import Digital from "../../Components/digital/Digital";
import Film from "../../Components/film/Film";
import Video from "../../Components/video/Video";
import Cgi from "../../Components/Cgi/Cgi";
import Contact from "../../Components/Contact/Contact";
import Footer from "../../Components/Footer/Footer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import Why from "./Why";
import growl from "../../assets/growl.png";
import potential from "../../assets/potential.png";
import ServiceCards from "../../Components/ServiceCards/ServiceCards";

gsap.registerPlugin(ScrollTrigger);

function Home() {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const thirdBanner = document.querySelector(".third-banner");

    if (thirdBanner) {
      const thirdBannerTop = thirdBanner.offsetTop;
      const thirdBannerHeight = thirdBanner.clientHeight;

      document.querySelectorAll(".circle-item").forEach((img, index) => {
        const correspondingCard = document.querySelector(`.card-${index + 1}`);

        if (correspondingCard) {
          const imgInitialTop = img.offsetTop;
          const imgInitialLeft = img.offsetLeft;

          const startScroll = thirdBannerTop - window.innerHeight;
          const endScroll = thirdBannerTop + thirdBannerHeight;

          const initialSize = 69; // Initial image size in pixels
          const targetSize = 45; // Final size when the image reaches the card
          const sizeDifference = initialSize - targetSize;

          const speedMultiplier = 2;

          if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
            let progress =
              (scrollPosition - startScroll) / (endScroll - startScroll);
            progress = Math.min(progress * speedMultiplier, 1);

            // Correct center positioning for images inside the cards
            const cardCenterX =
              correspondingCard.offsetLeft +
              correspondingCard.offsetWidth / 2 -
              img.offsetWidth / 2; // Adjusted for centering
            const cardCenterY =
              correspondingCard.offsetTop +
              correspondingCard.offsetHeight / 2 -
              img.offsetHeight / 2 -
              15; // Adjusted for centering and 7px up from the bottom

            // Calculate translation values
            const targetX = cardCenterX - imgInitialLeft;
            const targetY = cardCenterY - imgInitialTop;

            // Scale calculation
            const scale = 1 - progress * (sizeDifference / initialSize);

            // Apply translation and scaling
            img.style.transform = `translate(${targetX * progress}px, ${
              targetY * progress
            }px) scale(${scale})`;
          }

          if (scrollPosition > endScroll) {
            const cardCenterX =
              correspondingCard.offsetLeft +
              correspondingCard.offsetWidth / 2 -
              img.offsetWidth / 2; // Centering for final position
            const cardCenterY =
              correspondingCard.offsetTop +
              correspondingCard.offsetHeight / 2 -
              img.offsetHeight / 2 -
              15; // Centering for final position and 7px up from the bottom

            img.style.transform = `translate(${
              cardCenterX - imgInitialLeft
            }px, ${cardCenterY - imgInitialTop}px) scale(${
              targetSize / initialSize
            })`;
          }

          if (scrollPosition < startScroll) {
            img.style.transform = `translate(0, 0) scale(1)`;
          }
        }
      });
    }
  }, [scrollPosition]);

  const location = useLocation(); // Access the current path

  const [selectedTab, setSelectedTab] = useState("Graphic Designing"); // State to track selected tab

  // Tab click handler
  const handleTabClick = (tab) => {
    setSelectedTab(tab); // Set the selected tab
    console.log(tab);
    const service = document.querySelector("#service");
    if (service) {
      service.scrollIntoView({ behavior: "smooth" });
    }
    
  };

  const handleConsultationClick = () => {
    const contactSection = document.querySelector("#contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePdfOpen = () => {
    window.open("/FCPL PROFILE_2024.pdf", '_blank'); 
  }

  return (
    <>
      {/* <----Top Banner Start----> */}  
      <section className="Top-Banner">
        <div className="top-div">
          <div className="main-div">
            <div className="top-bg-banner">
              <div className="top-banner">
                <div className="logo-container">
                <a
                  href="https://teamflourish.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logo} alt="logo-flourish-top" className="logo" />
                  </a>
                </div>
                <div className="top">
                  <span className="grow-container">
                    Grow
                    <img
                      src={growl}
                      alt="underline"
                      className="grow-underline"
                    />
                  </span>{" "}
                  Your Brand With Our Comprehensive <br/>Branding & Marketing Solutions
                </div>

                <div className="btn-container">
                  <Button
                    BtnName="Book a Free Consultation"
                    onClickBtn={handleConsultationClick}
                  />
                  <WhiteBtn BtnName="View Portfolio" onClickBtn={handlePdfOpen}/>
                </div>

                <div className="circle">
                  <img src={TopEmojiImg} alt="" className="top-emoji-img" />
                  <div className="img-radius">
                    <div className="circle-item img1" onClick={() => handleTabClick("Graphic Designing")} >
                      <img src={one} alt="img1" className="imgs-ani" />
                    </div>
                    <div className="circle-item img2" onClick={() => handleTabClick("Web Development")} >
                      <img src={two} alt="img2" className="imgs-ani" /> 
                    </div>
                    <div className="circle-item img3" onClick={() => handleTabClick("Digital Marketing")}>
                      <img src={three} alt="img3" className="imgs-ani"  />
                    </div>
                    <div className="circle-item img4" onClick={() => handleTabClick("Film Production")}>
                      <img src={four} alt="img4" className="imgs-ani" />
                    </div>
                    <div className="circle-item img5" onClick={() => handleTabClick("Video Advertisement")}>
                      <img src={five} alt="img5" className="imgs-ani " />
                    </div>
                    <div className="circle-item img6"  onClick={() => handleTabClick("CGI Videos")}>
                      <img src={six} alt="img6" className="imgs-ani" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <----Top Banner End----> */}
      {/* <----Second Banner Start----> */}
      <section className="Second-Banner bg-white">
        <div className="second-banner-title">
          Unlock Your Brand’s{" "}
          <span className="grow-container">
            Potential
            <img
              src={potential}
              alt="underline"
              className="potential-underline"
            />
          </span>
        </div>
        <div className="second-banner-desc-text">
          At Flourish Creations Private Limited, we empower businesses with a
          360° approach to digital success. Whether you're looking to build a
          stunning website, craft impactful digital marketing campaigns, or
          create visually striking graphics and videos, we are your trusted
          partners in growth.
        </div>
      </section>
      {/* <----Second Banner End----> */}
      {/* <----Third Banner Start----> */}

      <section className="Third-section bg-white display-in-desk">
        <div className="service-container" >
          <div className="service-title" id="service">
            Our{" "}
            <span className="grow-container">
              Services
              <img
                src={potential}
                alt="underline"
                className="potential-underline"
              />
            </span>
          </div>
          <div className="third-banner">
            <div
              className={`cards card-1 ${
                selectedTab === "Graphic Designing" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Graphic Designing")}
            >
              <span className="card-txt">Graphic Designing</span>
            </div>
            <div
              className={`cards card-2 ${
                selectedTab === "Web Development" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Web Development")}
            >
              <span className="card-txt"> Web Development</span>
            </div>
            <div
              className={`cards card-3 ${
                selectedTab === "Digital Marketing" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Digital Marketing")}
            >
              <span className="card-txt"> Digital Marketing </span>
            </div>
            <div
              className={`cards card-4 ${
                selectedTab === "Film Production" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Film Production")}
            >
              <span className="card-txt"> Film Production</span>
            </div>
            <div
              className={`cards card-5  ${
                selectedTab === "Video Advertisement" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Video Advertisement")}
            >
              <span className="card-txt"> Video Advertisement</span>
            </div>
            <div
              className={`cards card-6 ${
                selectedTab === "CGI Videos" ? "active" : ""
              }`}
              onClick={() => handleTabClick("CGI Videos")}
            >
              <span className="card-txt"> CGI Videos </span>
            </div>
          </div>

          <div className="tab-content">
            <p>
              {selectedTab === "Graphic Designing" && <Graphic />}
              {selectedTab === "Web Development" && <WebDevelopment />}
              {selectedTab === "Digital Marketing" && <Digital />}
              {selectedTab === "Film Production" && <Film />}
              {selectedTab === "Video Advertisement" && <Video />}
              {selectedTab === "CGI Videos" && <Cgi />}
            </p>
          </div>
        </div>
      </section>

      <section className="display-service-tab-mobile">
        <ServiceCards />
      </section>

      {/* <----Third Banner End----> */}
      {/* <----Four Banner Start----> */}

      <Why />
      <section className="Second-Banner bg-white">
        <div className="second-banner-title lets-build">
          Let’s Build Your Success{" "}
          <span className="grow-container">
            Together!
            <img
              src={potential}
              alt="underline"
              className="together-underline"
            />
          </span>
        </div>
        <div className="second-banner-desc-text">
          Ready to elevate your brand? Contact us today for a free consultation,
          and let’s discuss how we can help you achieve your business goals with
          our cutting-edge digital marketing services.
        </div>
      </section>
      <section id="contact">
        <Contact />
      </section>
    </>
  );
}

export default Home;
