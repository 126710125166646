
import React from 'react';
import '../Button/Button.css';

function Button({ BtnName, btnType, onClickBtn }) {
  return (
    <div className="main-btn">
      <button type={btnType} onClick={onClickBtn} className='btn-blue'>
        <span className="transition"></span>
        <span className='btn-text'>{BtnName}</span>
      </button>
    </div>
  );
}

export default Button;
