import React from "react";
import "../SeviceCardBanner/ServiceCardBanner.css";
import Button from "../../Components/Button/Button";
import WhiteBtn from "../../Components/WhiteBtn/WhiteBtn";
import { Link, useNavigate } from "react-router-dom";


function ServiceCardBanner({ title, description, servicesList, desc, gif ,navilink}) {
  const navigate= useNavigate();
  const handleConsultationClick = () => {
    const contactSection = document.querySelector("#contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <div className="content-1380">
        <div className="card-banner-container display-flex">
          <div className="gif-container">
            <div className="gif-img">
              <img src={gif} className="img-of-gif" />
            </div>
          </div>
          <div className="content-banners">
            <div className="title-service-card-banners">{title}</div>
            <div className="desc-service-card-banners p-5">{description}</div>
            <div className="points-service-card-banners">
              <ul className="points-font">
                {servicesList.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            </div>
            <div className="desc-service-card-banners p-30">{desc}</div>
            <div className="btn-flex">
            <Button
              BtnName="Book a Free Consultation"
              onClickBtn={handleConsultationClick}
            />
            <a href= {navilink} target="_blank">
            <WhiteBtn BtnName="Our Projects"/>
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCardBanner;
