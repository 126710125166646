import React from 'react';
import ServiceCardBanner from '../SeviceCardBanner/ServiceCardBanner';
import digital from "../../assets/digital.gif";

function Digital() {
  const digitalList = [
    "Search Engine Optimization (SEO)",
    "Pay-Per-Click (PPC) Advertising",
    "Social Media Marketing (SMM)",
    "Email Marketing",
    "Social Media Management",
  ];
  return (
    <div>
      <ServiceCardBanner
        title="Digital Marketing"
        description="Reach the right audience at the right time with our data-driven digital marketing strategies. We offer comprehensive marketing solutions to drive traffic, boost engagement, and increase conversions"
        servicesList={digitalList}
        desc="Leverage our expertise to turn clicks into customers and elevate your online presence."
        gif={digital}
        navilink="https://drive.google.com/drive/folders/1L1RNlvyKhxFdnZX7KDwUJvwLiWVDwUUp?usp=sharing"
      />
    </div>
  );
}

export default Digital;
