import React, { useState } from "react";
import Graphic from "../../Components/Graphic/Graphic";
import WebDevelopment from "../../Components/WebDevelopment/WebDevelopment";
import Digital from "../../Components/digital/Digital";
import Film from "../../Components/film/Film";
import Video from "../../Components/video/Video";
import Cgi from "../../Components/Cgi/Cgi";
import potential from "../../assets/potential.png";
import one from "../../assets/one.svg";
import two from "../../assets/two.svg";
import three from "../../assets/three.svg";
import four from "../../assets/four.svg";
import five from "../../assets/five.svg";
import six from "../../assets/six.svg";
import "../ServiceCards/ServiceCards.css";

function ServiceCards() {
  const [selectedTab, setSelectedTab] = useState("Graphic Designing"); // State to track selected tab

  // Tab click handler
  const handleTabClick = (tab) => {
    setSelectedTab(tab); // Set the selected tab
  };
  return (
    <>
      <section className="Third-section bg-white">
        <div className="service-container">
          <div className="service-title">
            Our{" "}
            <span className="grow-container">
              Services
              <img
                src={potential}
                alt="underline"
                className="potential-underline"
              />
            </span>
          </div>
          <div className="width-container">
            <div className="third-banner">
              <div
                className={`cards card-1 ${
                  selectedTab === "Graphic Designing" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Graphic Designing")}
              >
                <div
                  className="circle-item-mobile "
                  onClick={() => handleTabClick("Graphic Designing")}
                >
                  <img src={one} alt="img1" className="imgs-ani-mobile " />
                </div>
                <span className="card-txt">Graphic Designing</span>
              </div>
              <div
                className={`cards card-2 ${
                  selectedTab === "Web Development" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Web Development")}
              >
                <div
                  className="circle-item-mobile "
                  onClick={() => handleTabClick("Web Development")}
                >
                  <img src={two} alt="img1" className="imgs-ani-mobile" />
                </div>
                <span className="card-txt"> Web Development</span>
              </div>
              <div
                className={`cards card-3 ${
                  selectedTab === "Digital Marketing" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Digital Marketing")}
              >
                <div
                  className="circle-item-mobile"
                  onClick={() => handleTabClick("Digital Marketing")}
                >
                  <img src={three} alt="img1" className="imgs-ani-mobile" />
                </div>
                <span className="card-txt"> Digital Marketing </span>
              </div>
              <div
                className={`cards card-4 ${
                  selectedTab === "Film Production" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Film Production")}
              >
                <div
                  className="circle-item-mobile"
                  onClick={() => handleTabClick("Film Production")}
                >
                  <img src={four} alt="img1" className="imgs-ani-mobile" />
                </div>
                <span className="card-txt"> Film Production</span>
              </div>
              <div
                className={`cards card-5 ${
                  selectedTab === "Video Advertisement" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Video Advertisement")}
              >
                <div
                  className="circle-item-mobile "
                  onClick={() => handleTabClick("Video Advertisement")}
                >
                  <img
                    src={five}
                    alt="img1"
                    className="imgs-ani-mobile v-icon"
                  />
                </div>
                <span className="card-txt"> Video Advertisement</span>
              </div>
              <div
                className={`cards card-6 ${
                  selectedTab === "CGI Videos" ? "active" : ""
                }`}
                onClick={() => handleTabClick("CGI Videos")}
              >
                <div
                  className="circle-item-mobile "
                  onClick={() => handleTabClick("CGI Videos")}
                >
                  <img src={six} alt="img1" className="imgs-ani-mobile" />
                </div>
                <span className="card-txt"> CGI Videos </span>
              </div>
            </div>
          </div>

          <div className="tab-content">
            <p>
              {selectedTab === "Graphic Designing" && <Graphic />}
              {selectedTab === "Web Development" && <WebDevelopment />}
              {selectedTab === "Digital Marketing" && <Digital />}
              {selectedTab === "Film Production" && <Film />}
              {selectedTab === "Video Advertisement" && <Video />}
              {selectedTab === "CGI Videos" && <Cgi />}
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceCards;
