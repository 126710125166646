import React, { useEffect, useRef, useState } from "react";
import "../Contact/Contact.css";
import { IoIosArrowDown } from "react-icons/io";
import Swal from "sweetalert2";
import Button from "../Button/Button";
import growl from "../../assets/growl.png";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import contactline from "../../assets/contactline.png";
import { useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();

  const [date, setDate] = useState();
  const [formattedDatee, setformattedDate] = useState();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isTimeDropdownOpen, setIsTimeDropdownOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState();
  const [phoneError, setPhoneError] = useState(""); 
  const [emailError, setEmailError] = useState(""); 
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if form has been submitted



  // const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
  // const emailRegex = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;

  // const handlePhoneChange = (e) => {
  //   setPhoneNumber(e.target.value);
  // };

  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);
  // };
  // const validatePhone = (phone) => {
  //   if (!phoneRegex.test(phone)) {
  //     setPhoneError("Please enter a valid 10-digit phone number.");
  //   } else {
  //     setPhoneError("");
  //   }
  // };
  // const validateEmail = (email) => {
  //   if (!emailRegex.test(email)) {
  //     setEmailError("Please enter a valid email address.");
  //   } else {
  //     setEmailError("");
  //   }
  // };

  // References to track clicks outside the calendar and time dropdown
  const calendarRef = useRef(null);
  const timeDropdownRef = useRef(null);
  const currTime = String(
    new Date().toLocaleTimeString("en-GB", { hour: "2-digit" })
  );
  // let timeSlots = [
  //   "10:00 - 11:00",
  //   "11:00 - 12:00",
  //   "12:00 - 13:00",
  //   "13:00 - 14:00",
  //   "14:00 - 15:00",
  //   "15:00 - 16:00",
  //   "16:00 - 17:00",
  //   "17:00 - 18:00",
  //   "18:00 - 19:00",
  // ];
  let [timeSlots, setTimeslots] = useState([
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
  ]);

  // verify time
  const timeCheck = () => {
    let arr = [];
    for (let i = 0; i <= timeSlots.length - 1; i++) {
      let x = String(timeSlots[i]).substring(0, 2);
      console.log(x, "rfgh");
      if (currTime <= x) {
        console.log(timeSlots[i], "i");
        arr.push(timeSlots[i]);
      } else {
        console.log(x, "x");
      }
    }

    setTimeslots(arr);
    console.log(timeSlots);
  };

  // Handle time selection
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setIsTimeDropdownOpen(false);
  };

  // Toggle Calendar open/close
  const toggleCalendar = () => {
    if (isTimeDropdownOpen) {
      setIsTimeDropdownOpen(false); // Close time dropdown if open
    }
    setIsCalendarOpen((prevState) => !prevState); // Toggle calendar
  };

  // Toggle time dropdown
  const toggleTimeDropdown = () => {
    if (isCalendarOpen) {
      setIsCalendarOpen(false); // Close calendar if open
    }
    setIsTimeDropdownOpen((prevState) => !prevState); // Toggle time dropdown
  };

  // Add click outside detection for calendar and time dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        timeDropdownRef.current &&
        !timeDropdownRef.current.contains(event.target)
      ) {
        setIsCalendarOpen(false);
        setIsTimeDropdownOpen(false);
      }
    };
    console.log(currTime, "tiimee");

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [detail, setDetail] = useState({
    name: "",
    email: "",
    phone: "",
    companyname: "",
    message: "",
    time: "",
    date: "",
    service: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
    // if (name === "phone") {
    //   validatePhone(value); 
    // }
    // if (name === "email") {
    //   validateEmail(value); 
    // }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitted(true); // Mark the form as submitted
      const phoneRegex = /^\d{10}$/;

      // Validate phone number (10 digits)
      if (!phoneRegex.test(detail.phone)) {
        setPhoneError('Please enter 10 digits');
        return
      } else {
        setPhoneError('');
        
      }
  
      // Validate email
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(detail.email)) {
        setEmailError('Please enter a valid email');
        return
      } else {
        setEmailError('');
        
      }
      setIsLoading(true);

      let data = await fetch("https://contact.fcplgroup.com/user/add", {
        method: "POST",
        body: JSON.stringify({
          ...detail,
          time: selectedTime,
          date: formattedDatee,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      data = await data.json();
      console.log(data);
      navigate("/thankyou");

      // // SweetAlert2 success message
      // Swal.fire({
      //   title: "Form Submited Successfully!",
      //   // text: "Submit Successfully",
      //   icon: "success",
      //   confirmButtonText: "OK",
      // });

      setDetail({
        name: "",
        email: "",
        phone: "",
        companyname: "",
        whatsapp: "",
        message: "",
        service: "",
        date: formattedDatee,
        time: selectedTime,
      });
      setDate();
      setSelectedTime("Select Time");
      console.log(data, "check data");
    } catch (error) {
      console.log(error);

      Swal.fire({
        title: "Error!",
        text: "Something went wrong. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setIsLoading(false);
    }
  };

  const handleDateChange = (newDate) => {
    const formattedDate = newDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    if (
      newDate.toLocaleDateString("en-GB") ==
      new Date().toLocaleDateString("en-GB")
    ) {
      console.log("today");
      timeCheck();
    } else {
      setTimeslots([
        "10:00 - 11:00",
        "11:00 - 12:00",
        "12:00 - 13:00",
        "13:00 - 14:00",
        "14:00 - 15:00",
        "15:00 - 16:00",
        "16:00 - 17:00",
        "17:00 - 18:00",
        "18:00 - 19:00",
      ]);
    }

    setDate(newDate);
    setformattedDate(formattedDate);
    toggleCalendar();
  };

  return (
    <>
    <div className="contact-container bg-white">
      {/* Show loader if isLoading is true */}
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      {/* Apply blur effect to the body when loading */}
      {isLoading && <div className="body-blur"></div>}

      {/* <div className="contact-container bg-white "> */}
        <div className="contant-title">
          Book A Free{" "}
          <span className="grow-container">
            Consultation
            <img src={contactline} alt="underline" className="form-underline" />
          </span>
        </div>
        <div className="contact-form-top">
          <form onSubmit={handleSubmit}>
            <div className="contact-form">
              <div className="name-contact-row">
                <div className="input-field name-contact">
                  <div className="input-group name-contact-input">
                    <input
                      type="text"
                      className="input"
                      placeholder="Name"
                      required
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={detail.name}
                    />
                  </div>
                </div>
                <div className="input-field name-contact">
                  <div className="input-group name-contact-input">
                    <input
                      type="text"
                      className="input"
                      placeholder="Phone Number"
                      name="phone"
                      required
                      onChange={(e) => handleChange(e)}
                      value={detail.phone}
                    />
                   
                  </div>
                  { phoneError && <span className="error-message">{phoneError}</span>}

                </div>
              </div>
              <div className="name-contact-row">
                <div className="input-field name-contact">
                  <div className="input-group name-contact-input">
                    <input
                      type="email"
                      className="input"
                      placeholder="Email"
                      name="email"
                      required
                      onChange={(e) => handleChange(e)}
                      value={detail.email}
                    />
                  </div>
                  {isSubmitted && emailError &&  <span className="error-message">{emailError}</span>}
                </div>

                <div className="input-field name-contact">
                  <div className="input-group name-contact-input ">
                    <select
                      className="input-dropdown"
                      name="service"
                      required
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select Service</option>
                      <option value="Graphic Designing" className="change-blue">
                        Graphic Designing
                      </option>
                      <option value="Web Development">Web Development</option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                      <option value="Film Production">Film Production</option>
                      <option value="Video Advertisement">
                        Video Advertisement
                      </option>
                      <option value="CGI Videos">CGI Videos</option>
                    </select>
                    <span className="arrow-contact">
                      <IoIosArrowDown className="dropdown-icon" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="name-contact-row">
                <div className="input-field name-contact">
                  <div className="input-group name-contact-input">
                    <input
                      type="text"
                      className="input"
                      placeholder="Company Name"
                      name="companyname"
                      required
                      onChange={(e) => handleChange(e)}
                      value={detail.companyname}
                    />
                  </div>
                </div>
                <div className="name-contact-row">
                  <div className="input-field name-contact" ref={calendarRef}>
                    <div className="input-group name-contact-input">
                      <button
                        className="select-date-btn input"
                        type="button"
                        onClick={toggleCalendar}
                      >
                        {date
                          ? date.toLocaleDateString("en-GB")
                          : "Select Date"}
                        <IoIosArrowDown className="dropdown-icon" />
                      </button>
                      {isCalendarOpen && (
                        <div className="calendar-container">
                          <Calendar
                            onChange={handleDateChange}
                            value={date}
                            minDate={new Date()}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="input-field name-contact"
                    ref={timeDropdownRef}
                  >
                    <div className="input-group name-contact-input">
                      <button
                        className="select-time-btn input"
                        type="button"
                        onClick={toggleTimeDropdown}
                      >
                        {selectedTime || "Select Time"}
                        <IoIosArrowDown className="dropdown-icon" />
                      </button>
                      {isTimeDropdownOpen && (
                        <div className="time-dropdown">
                          <div className="dropdown-title">Time</div>
                          {timeSlots.map((time, index) => (
                            <div
                              key={index}
                              className={`time-option ${
                                selectedTime === time ? "selected" : ""
                              }`}
                              onClick={() => handleTimeSelect(time)}
                            >
                              {time}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="message-row">
                <div className="input-fieldd">
                  <div className="input-group">
                    <textarea
                      className="input"
                      placeholder="Message"
                      name="message"
                      required
                      onChange={(e) => handleChange(e)}
                      value={detail.message}
                    />
                  </div>
                   {/* Submit button */}
            {!isLoading && (
              <div className="contact-button">
                <Button BtnName="Submit" btnType="submit" />
              </div>
            )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
