import React from "react";
import footerLogo from "../../assets/logo.svg";
import "../../Components/Footer/Footer.css";
import { MdOutlineMail } from "react-icons/md";
import { PiPhone } from "react-icons/pi";
import wp from "../../assets/wp.png";
import fb from "../../assets/fb.png";
import insta from "../../assets/insta.png";
import linkedin from "../../assets/linkedin.png";
import { FaInstagram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io5";
import { LiaFacebookF } from "react-icons/lia";
import { TfiWorld } from "react-icons/tfi";
import mail from "../../assets/mail.png";
import { CiMail } from "react-icons/ci";
import { MdMailOutline } from "react-icons/md";
import { GoMail } from "react-icons/go";


import { FaLinkedinIn } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer-bg bg-white">
        <div className="footer-1320">
          <div className="footer-flex">
            <div className="footer-logo">
              <a
                href="https://teamflourish.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={footerLogo} alt="" className="logo"/>
              </a>
            </div>
            <div className="center-foogter">
            {/* <a
                href="tel:+919499619901"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="phone">
                  <PiPhone />
                  (+91)<span className="semibold"> 94996 19901</span>
                </div>
              </a> */}
            <div className="email-phone">
              <Link
                to="https://teamflourish.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="email">
                  <TfiWorld />

                  <span className="semibold"> www.teamflourish.co</span>
                </div>
              </Link>
              <hr className="hr-rotate-phone" />
              <a
                href="mailto:info@teamflourish.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="phone">
                <GoMail/>
                  <span className="semibold"> info@teamflourish.co</span>
                </div>
              </a>
            </div>
            </div>
            <div className="social-container">
              <hr className="hr-width" />
              <div className="social-icons">
                <hr className="hr-rotate" />
                <a
                  href="https://wa.me/9499619901"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="28.57px" // Set your desired width
                    height="28.57px" // Set your desired height
                    viewBox="0 0 16 15.5"
                  >
                    <defs>
                      <linearGradient
                        id="whatsappGradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: "#42EF62", stopOpacity: 1 }}
                        />
                        <stop
                          offset="99.52%"
                          style={{ stopColor: "#19CB3C", stopOpacity: 1 }}
                        />
                      </linearGradient>
                    </defs>

                    <IoLogoWhatsapp
                      style={{
                        fill: "url(#whatsappGradient)",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </svg>
                </a>
                {/* <img src={wp} alt="" className="iconss" /> */}
                <hr className="hr-rotate" />
                <svg
                  width="28.57"
                  height="28.57"
                  viewBox="0 0 16 15.5"
                  style={{ overflow: "visible" }}
                >
                  <defs>
                    <linearGradient
                      id="instagramGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#0496FB", stopOpacity: 1 }}
                      />
                      <stop
                        offset="13.83%"
                        style={{ stopColor: "#3F72FF", stopOpacity: 1 }}
                      />
                      <stop
                        offset="27.95%"
                        style={{ stopColor: "#784AFF", stopOpacity: 1 }}
                      />
                      <stop
                        offset="42.11%"
                        style={{ stopColor: "#B038EE", stopOpacity: 1 }}
                      />
                      <stop
                        offset="56.56%"
                        style={{ stopColor: "#CD40BE", stopOpacity: 1 }}
                      />
                      <stop
                        offset="71.98%"
                        style={{ stopColor: "#FF415A", stopOpacity: 1 }}
                      />
                      <stop
                        offset="86.47%"
                        style={{ stopColor: "#F7941E", stopOpacity: 1 }}
                      />
                      <stop
                        offset="98.07%"
                        style={{ stopColor: "#FFE800", stopOpacity: 1 }}
                      />
                    </linearGradient>
                  </defs>

                  <a
                    href="https://www.instagram.com/team.flourish_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram
                      style={{
                        fill: "url(#instagramGradient)",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </a>
                </svg>
                {/* <img src={insta} alt="" className="iconss" /> */}
                <hr className="hr-rotate" />
                <div className="icon-gradient">
                  <a
                    href="https://in.linkedin.com/company/flourish-creations-pvt-ltd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>{" "}
                <hr className="hr-rotate" />
                <a
                  href="https://www.facebook.com/profile.php?id=100084758949547"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="fb">
                    <svg
                      width="15.57px" // Set your desired width
                      height="28.57px" // Set your desired height
                      // viewBox="0 0 17 20"
                      style={{ overflow: "visible" }}
                    >
                      <defs>
                        <linearGradient
                          id="facebookGradient"
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="0%"
                        >
                          <stop
                            offset="0%"
                            style={{ stopColor: "#19AEFF", stopOpacity: 1 }}
                          />
                          <stop
                            offset="99.52%"
                            style={{ stopColor: "#0065E7", stopOpacity: 1 }}
                          />
                        </linearGradient>
                      </defs>
                      <LiaFacebookF fill="url(#facebookGradient)" />
                    </svg>{" "}
                  </div>
                </a>
                <hr className="hr-rotate" />
              </div>
              <hr className="hr-bottom" />
            </div>
          </div>
        </div>
        {/* <hr className="hr-color"></hr>
        <div className="copy">
          Copyrights © 2024 Flourish All rights reserved.
        </div> */}
      </div>
    </>
  );
}

export default Footer;
